import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Box, Button, Grid } from '@core';
import { ImageCore, LinkWrapper, Text } from '@components';
import { ArrowIcon } from '@components';

import { getVariant } from '@shared/style-helpers';
import { isLTR } from '@shared/translation-helpers';

// TODO current solution to link arrow hover state needs a second look

const Line = styled.hr`
  position: absolute;
  bottom: 0;
  margin: 0;
  border: none;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary};

  height: 0;
  transition: height 200ms;
`;

const variants = {
  default: css`
    position: relative;
    padding-bottom: 24px;
    width: 144px;

    &:hover ${Line}, .active ${Line} {
      height: 4px;
    }
  `,
  secondary: css`
    max-width: 135px;

    &:hover p,
    .active p {
      color: ${({ theme, link }) => link && theme.colors.primary};
    }
  `,
};

const StyledContainer = styled.div`
  display: inline-block;

  &:hover {
    svg#arrow {
      path:nth-child(1) {
        fill: ${({ theme }) => theme.colors.primary_80};
      }

      path:nth-child(2) {
        fill: ${({ theme }) => theme.colors.primary};
      }
    }
  }

  ${getVariant(variants)}
`;

const IconLinkCard = ({ image, title, link, customEvent, variant = 'default', showArrow }) => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <StyledContainer variant={variant} link={link}>
      <LinkWrapper link={link} activeClassName="active" partiallyActive={false}>
        <Grid.Container>
          <Button customEvent={customEvent} type="button" variant="text">
            <Grid.Row>
              <Grid.Col display="flex" justifyContent="center" height={32}>
                <ImageCore src={image} alt={title} />
              </Grid.Col>
              <Grid.Row mt={24} alignItems="center" justifyContent="center">
                <Text.Body3Strong textAlign="center">{title}</Text.Body3Strong>
                {showArrow && (
                  <Box as="span" ml={12} display="flex" alignItems="center" width={7} height={12}>
                    <ArrowIcon deg={isLTR(language) ? 0 : 180} />
                  </Box>
                )}
              </Grid.Row>
            </Grid.Row>
          </Button>
        </Grid.Container>
        {link && <Line />}
      </LinkWrapper>
    </StyledContainer>
  );
};

export default IconLinkCard;
